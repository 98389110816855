import { API_GET_REALM_LIST } from '~/static/urls/general';
import { useRuntimeConfig } from '#app';
import useRealmStore from '@/stores/useRealmStore';

export default () => {
  const realmObj = useRealmStore();
  const runtimeConfig = useRuntimeConfig();
  const apiBase = runtimeConfig.public.apiBase;
  // eslint-disable-next-line no-undef
  return $fetch(API_GET_REALM_LIST, {
    method: 'GET',
    baseURL: apiBase
  })
    .then((response) => {
      realmObj.setRealmData(response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      realmObj.setRealmData([]);
      return Promise.reject(error.response);
    });
};
